.footer {
  display: flex !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 0 !important;
  z-index: 999 !important;
}

.footer-layout {
  background-color: #e4e7ee !important;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(240, 240, 240);
  height: 50px;
  width: 100% !important;
  justify-content: center;
  align-items: center;
}

.footer-text {
  font-family: var(--font-family) !important;
  color: black !important;
  font-size: var(--font-size) !important;
  letter-spacing: 0.04em !important;
  font-weight: bold !important;
}
