.login-textbox {
  width: 80% !important;
}

.login {
  position: absolute;
  top: 35%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  border-radius: 25px !important;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px !important;
  margin-bottom: 5px !important;
  margin-left: 35px !important;
}

.label-text {
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
}

.pcs-logo-login {
  padding: 15px !important;
}

.login-button {
  background-color: var(--common-color) !important;
  margin-top: 40px !important;
}

.error-text {
  padding: 20px !important;
  font-family: var(--font-family) !important;
  color: var(--common-error-color) !important;
  font-size: var(--font-size) !important;
}

.background-container {
  background-size: cover !important;
  background-position: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.grid-container {
  height: 100vh !important;
}

.image-grid-item {
  background-repeat: no-repeat !important;
  background-size: 500px !important;
  background-position: center !important;
}

.login-container {
  box-shadow: 1 !important;
  border-radius: 25px !important;
  padding: 6px 16px !important;
  margin-top: 200px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-left: 90px !important;
  width: 65% !important;
  background-color: white !important;
  height: 470px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
