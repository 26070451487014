.header {
  display: flex !important;
  width: 100% !important;
  position: fixed !important;
  top: 0 !important;
  z-index: 1000 !important;
  margin-top: 0.5% !important;
  border-bottom: 1px solid rgb(240, 240, 240) !important;
  padding-bottom: 16px !important;
  background-color: white;
}

.pcs-logo {
  position: absolute;
  height: 55px;
  top: 0.15rem;
  left: 1rem;
}

.agritech-text {
  margin-left: 70px !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
  margin-top: 20px !important;
}

.header-icons-container {
  padding-right: 10px !important;
  margin-top: 10px !important;
}

.header-modal-icons {
  width: 23px !important;
  height: 23px !important;
}

.fill-black {
  filter: grayscale(100%) brightness(0%);
}

.tab-button-style {
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 4px 10px !important;
  text-transform: none !important;
  font-weight: bold !important;
  color: #03543f !important;
  width: 150px !important;
  margin-top: 10px !important;
  border-radius: 5px !important;
}

.tab-button-selected {
  background-color: #def7ec !important;
}

.header-icon-button {
  background-color: white !important;
}

.header-info-logout-icon {
  z-index: 1 !important;
  margin-left: 5px !important;
}

.header-icon {
  padding: 5px !important;
}

.header-icon:hover {
  background-color: #e9eaec !important;
  border-radius: 20px !important;
  cursor: pointer !important;
}

.info-card {
  padding: 5px 5px !important;
}

.header-info-list-width {
  width: 160px !important;
}

.profile-card {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 5px !important;
}

.header-icons-info-menu {
  left: auto !important;
  top: 52px !important;
  right: 13px !important;
}

.header-info-list-cursor {
  cursor: pointer !important;
}

.selected-header-info {
  background-color: #f6fbff !important;
  cursor: default !important;
}
