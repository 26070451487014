.no-access-text {
  font-family: var(--font-family) !important;
  color: #778094 !important;
  font-size: 3rem !important;
  font-weight: 800 !important;
}

.no-access {
  background-color: #f4f5f7 !important;
  height: 100vh !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
