.item-list-accordion {
  width: 80% !important;
  margin: 2px 0 0px 100px !important;
}

.item-list-accordion:last-child {
  margin-bottom: 15px !important;
}

@media screen and (min-width: 1950px) {
  .item-list-accordion {
    width: 60% !important;
  }
}

.item-list-container {
  height: calc(100vh - 18rem);
  overflow: hidden;
  padding-right: 5px;
  background-color: #fff;
}

.item-list-container:hover {
  overflow-y: scroll;
  padding-right: 0px;
}

.item-accordion-container {
  max-height: 300px;
  overflow-y: auto;
  padding-left: 50px !important;
  padding-right: 50px !important;
  overflow: hidden;
}

.item-accordion-container:hover {
  overflow-y: scroll;
}

.items-tableCell {
  color: var(--common-color) !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
}

.party-text {
  font-weight: bold !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  margin-left: 0px !important;
}

.pending-bags-page-style {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.item-list-backIcon-container {
  margin-top: 90px !important;
  margin-bottom: 15px !important;
  margin-left: 40px !important;
}

.item-search-container {
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  margin-left: 50px !important;
}

.item-list-back-icon-style {
  color: var(--common-color) !important;
}

.pendingOrder-text {
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
}

.margin-style {
  margin-right: 4px !important;
}

.no-details-warning-text {
  padding: 20px !important;
  color: var(--common-error-color) !important;
  font-family: var(--font-family) !important;
}

.pending-bags-label {
  margin-top: 10px !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: bold !important;
}

.no-bags-error-text {
  color: var(--common-error-color) !important;
  font-family: var(--font-family) !important;
}

.no-bags-error-margin-top {
  margin-top: 70px !important;
}

.no-bags-error-wrapper {
  display: grid !important;
  place-items: center !important;
}

.no-bags-error-wrapper-height {
  height: 90vh !important;
}

.home-page-pending-bag-list {
  margin-top: 100px !important;
  margin-left: 50px !important;
}

.home-page-no-pending-bags-error-text-margin-top {
  margin-top: 250px !important;
}
