body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --font-family: "Roboto" !important;
  --font-size: 14px !important;
  --common-color: #009a2b !important;
  --common-disabled-color: #aaacae !important;
  --common-error-color: #d32f2f !important;
  --common-border-style: 1px solid rgb(0, 0, 0, 0.2) !important;
  --kpi-average-color: #03008d !important;
  --kpi-bad-color: #ff0000 !important;
  --kpi-analysis-font-color: #575587 !important;
  --analysis-chart-label-color: #023f5a !important;
}
