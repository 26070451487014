.order-payment-page-area {
  position: fixed !important;
  top: 5 !important;
  left: 0 !important;
  height: 100% !important;
  margin-top: 150px !important;
}

.order-grid {
  border-right: var(--common-border-style) !important;
}

.customer-list-header {
  padding: 15px !important;
}

.customer-list-header-text {
  font-family: var(--font-family) !important;
  font-weight: bold !important;
  text-align: left !important;
}

.customer-details-list-text {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  text-align: left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
  display: block !important;
}

.customer-list-header-divider {
  margin-left: 5px !important;
  border: var(--common-border-style) !important;
}

.customer-list-details-divider {
  border-bottom: var(--common-border-style) !important;
  padding: 10px !important;
}

.storeIcon-style {
  width: 17px !important;
  height: 17px !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
}

.customer-order-header-details-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
  margin-left: 20px !important;
}

.customer-header-list-text {
  display: flex;
  align-items: center;
  font-family: var(--font-family) !important;
  color: var(--common-color) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.colon-style {
  margin: 0 8px;
}

.customer-header-details-list-text {
  color: black !important;
  margin-left: 10px !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  text-align: left !important;
  font-weight: bold !important;
}

.customer-order-details-header {
  margin-top: 25px;
  padding: 0 0 0 10px;
  text-align: left;
}

.orders-payment-content--button {
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  height: 50px !important;
  width: 100% !important;
  color: black !important;
  text-align: center !important;
  text-transform: none !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.orders-payment-content--button--selected {
  background: #e4e7ee !important;
}

.list-container {
  max-height: 100% !important;
  overflow: hidden !important;
  padding-right: 5px !important;
  background-color: #fff !important;
}

.list-container:hover {
  overflow-y: auto !important;
}

.order-tab-height {
  max-height: calc(85vh - 12.5rem) !important;
}

@media screen and (min-width: 1950px) {
  .order-tab-height {
    max-height: calc(85vh - 7.5rem) !important;
  }
}

.order-details-page-height {
  height: calc(85vh - 13.5rem) !important;
}

::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbfc9;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbfc9;
}

.orders-text-header {
  color: black !important;
  font-weight: bold !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
}

.orders-table-cell-style {
  border-bottom: none !important;
  padding: 10px !important;
}

.commodity-details-text {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  text-align: left !important;
}

.order-details-modal-table {
  padding: 20px !important;
}

.bill-modal-transport-table {
  margin-top: -20px !important;
}

.bill-modal-text {
  font-family: var(--font-family) !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
  font-weight: bold !important;
}

.bill-modal-button {
  width: 134px !important;
  border-radius: 10px !important;
}

.bill-modal-update-button-disabled-bg-color {
  color: white !important;
  background-color: var(--common-disabled-color) !important;
}

.bill-modal-update-button-bg-color {
  color: white !important;
  background-color: var(--common-color) !important;
}

.bill-modal-cancel-button {
  color: var(--common-color) !important;
  border: 1px solid var(--common-color) !important;
  background-color: transparent;
}

.bill-modal-cancel-button-disabled {
  color: var(--common-disabled-color) !important;
  border: 1px solid var(--common-disabled-color) !important;
  background-color: transparent;
}

.bill-action-button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 20px;
}

.bill-action-button-style {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.bill-modal-scrollable-content {
  max-height: 40rem;
  overflow-y: auto;
  padding: 0 16px;
}

.bill-modal-font {
  font-family: var(--font-family) !important;
}

.billIcon-style {
  width: 25px !important;
  height: 25px !important;
  margin-right: 5px !important;
}

.bold-text {
  font-weight: bold !important;
}

.modal-error-text {
  color: var(--common-error-color) !important;
  font: var(--font-family) !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding-bottom: 10px !important;
}

.moveToBill-button {
  width: 140px !important;
  border-radius: 10px !important;
  text-transform: none !important;
  color: white !important;
  margin-right: 30px !important;
  text-align: center !important;
  background-color: var(--common-color) !important;
}

.moveToBill-button-bg-color {
  background-color: var(--common-color) !important;
}

.moveToBill-button-disabled-bg-color {
  background-color: var(--common-disabled-color) !important;
}

.orders-page-snackbar {
  width: 100% !important;
  margin-bottom: 40px !important;
}

.order-list {
  width: 100%;
}

@media screen and (min-width: 1950px) {
  .order-list {
    width: 92% !important;
  }
}

.customer-search-container-order {
  margin-top: 90px !important;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.order-customer-not-found {
  color: var(--common-error-color) !important;
  text-align: left !important;
  margin-left: 13px !important;
}

.custom-date-picker {
  min-width: 260px !important;
}

.bill-modal-arrow-icon {
  color: var(--common-color) !important;
}

.bill-modal-arrow-icon-disabled {
  color: var(--common-disabled-color) !important;
}

.bill-left-icon-style {
  justify-content: flex-start;
  padding-left: 35px !important;
}

.bill-right-icon-style {
  justify-content: flex-end;
  padding: 0px 30px 10px 0 !important;
  margin-top: 35px !important;
}

.modify-party-text {
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.modify-party-field {
  margin-top: 20px !important;
  width: 260px !important;
}

.bill-details-divider {
  border-bottom: var(--common-border-style) !important;
  padding: 4px !important;
}

.transport-details-table-item {
  cursor: pointer !important;
}

.expand-transport-details-table-item {
  border-bottom: none !important;
}

.bill-modal-expand-icon {
  height: 25px !important;
  width: 25px !important;
}

.bill-modal-expand-area {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  align-items: center !important;
}

.bill-modal-transport-remove-icon {
  color: #ff5e67 !important;
}

.bill-modal-transport-add-icon {
  color: #514eed !important;
}

.confirmation-modal-close-icon-button {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: #64748b !important;
}

.confirmation-modal {
  font-family: var(--font-family) !important;
  color: #1e293b !important;
}

.confirmation-modal-action-buttons {
  justify-content: center !important;
  padding-bottom: 20px !important;
}

.confirmation-modal-no-button {
  border: 1px solid var(--common-color) !important;
  color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  width: 84px !important;
  margin-left: 24px !important;
}

.confirmation-modal-yes-button {
  background-color: var(--common-color) !important;
  font-family: var(--font-family) !important;
  width: 84px !important;
}
