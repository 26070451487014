.customer-accordion {
  width: 80% !important;
  margin: 2px 0 0px 100px !important;
}

.customer-accordion:last-child {
  margin-bottom: 15px !important;
}

@media screen and (min-width: 1950px) {
  .customer-accordion {
    width: 60% !important;
  }
}

.customer-list-container {
  height: calc(100vh - 20rem);
  overflow: hidden;
  padding-right: 5px;
  background-color: #fff;
}

.customer-list-container:hover {
  overflow-y: scroll;
  padding-right: 0px;
}

.customer-accordion-container {
  max-height: 300px;
  overflow-y: auto;
  padding-left: 50px !important;
  padding-right: 50px !important;
  overflow: hidden;
}

.customer-accordion-container:hover {
  overflow-y: scroll;
}

.customers-tableCell {
  color: var(--common-color) !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  font-weight: bold !important;
}

.customer-party-text {
  font-weight: bold !important;
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
}

.pendingAmount-text {
  font-size: var(--font-size) !important;
  font-family: var(--font-family) !important;
  margin-left: 10px !important;
}

.customer-accordion-warnings {
  color: var(--common-error-color) !important;
  font-weight: 100 !important;
}

.outstanding-text {
  color: blue !important;
  font-size: 1rem !important;
  font-family: var(--font-family) !important;
}

.customer-page-style {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.backIcon-container {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-left: 40px !important;
}

.customer-search-container {
  margin-top: 10px !important;
  margin-bottom: 25px !important;
  margin-left: 50px !important;
}

.no-customer-details-warning {
  padding: 20px !important;
  color: var(--common-error-color) !important;
  font-family: var(--font-family) !important;
}

.error-record-not-found {
  color: var(--common-error-color) !important;
  text-align: left !important;
  margin-left: 53px !important;
}

.customer-warning-alert {
  margin-top: 70px !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
}

.customer-warning-text {
  font-weight: bold !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1px !important;
}

@media screen and (min-width: 1950px) {
  .customer-warning-text {
    margin-top: 3px !important;
  }
}

.has-missing-data-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: #ff9800 !important;
  border-radius: 50px !important;
  display: inline-block !important;
  margin-left: 10px !important;
  margin-top: 5px !important;
}

.search-input-field {
  margin-left: 14px !important;
  flex: 1 !important;
}

.customer-search {
  border: 1px solid #d9d9d9 !important;
  position: relative !important;
  padding: 2px 4px !important;
  display: flex !important;
  align-items: center !important;
  width: 250px !important;
  height: 42px !important;
  box-shadow: none !important;
  margin-top: 5px !important;
  border-radius: 5px !important;
}

.search-close-icon {
  width: 20px !important;
  height: 20px !important;
}
