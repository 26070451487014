/* ------------- Edit bill css ------------------*/

.edit-bill-page-area {
  position: fixed !important;
  height: 100% !important;
}

.edit-container {
  margin-top: 7px !important;
  margin-left: 20px !important;
}

.edit-bill-back-icon {
  margin-top: 85px !important;
  margin-bottom: 15px !important;
  margin-left: 20px !important;
}

.edit-search-field {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 30px !important;
}

.pending-bill-list-height {
  max-height: calc(85vh - 14.5rem) !important;
}

@media screen and (min-width: 1950px) {
  .pending-bill-list-height {
    max-height: calc(85vh - 11.5rem) !important;
  }
}

.pending-bill-rhs-list-height {
  max-height: calc(85vh - 11.5rem) !important;
}

@media screen and (min-width: 1950px) {
  .pending-bill-rhs-list-height {
    max-height: calc(85vh - 7.5rem) !important;
  }
}
