.home-page-categories {
  display: flex !important;
  justify-content: space-evenly !important;
  margin-top: 105px !important;
}

.home-page-categories-button {
  width: 14% !important;
  height: 33px !important;
  border-radius: 20px !important;
  padding: 8px !important;
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  box-shadow: none !important;
}
