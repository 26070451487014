.report-date-picker {
  min-width: 275px !important;
}

.report-divider {
  margin-left: 0px !important;
  margin-top: 27px !important;
  border: var(--common-border-style) !important;
}

.report-download-button {
  width: 134px !important;
  border-radius: 7px !important;
  margin-top: 17px !important;
}

.download-button-style {
  margin-left: 20px !important;
}

.download-icon-style {
  width: 17px !important;
  height: 17px !important;
  margin-left: 5px !important;
}

.report-table-header-row {
  color: black !important;
  font-family: var(--font-family) !important;
  background-color: #d5dbe4;
  font-weight: bold !important;
}

.report-details-container {
  margin-top: 25px !important;
  margin-bottom: 10px !important;
  margin-left: 40px !important;
  padding: 0 0 0 10px !important;
  text-align: left !important;
  width: 93.5% !important;
}

@media screen and (min-width: 1950px) {
  .report-details-container {
    width: 70% !important;
  }
}

.report-one-table-height {
  height: calc(85vh - 10.5rem) !important;
}

.report-two-table-height {
  height: calc(85vh - 15.5rem) !important;
}

.report-action-button-container {
  margin-left: auto !important;
  position: relative !important;
  margin-right: 50px !important;
}

@media screen and (min-width: 1950px) {
  .report-action-button-container {
    margin-right: 550px !important;
  }
}

.report-table-style tbody tr:nth-child(even) {
  background-color: #f2f5f8;
}

.form-field-grid {
  width: 150px;
  text-align: left;
}

.page-not-found {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
  text-align: center !important;
  width: 92% !important;
  margin: 50px 50px 10px !important;
}

.page-not-found-text {
  font-family: var(--font-family) !important;
  color: #778094 !important;
  font-size: 1.5rem !important;
  font-weight: 150 !important;
}

.select-field-clear-icon {
  margin-right: 9px !important;
}
