.analysis-background {
  background-color: #f5f5f9 !important;
  position: relative;
  width: fit-content !important;
  padding: 5px 5px 5px 25px !important;
  margin: 15px !important;
  max-height: 70vh !important;
  border-radius: 20px !important;
  overflow: auto;
  margin-top: 10px !important;
  margin-left: 25px !important;
}

@media screen and (min-width: 1950px) {
  .analysis-background {
    width: 96% !important;
  }
}

.analysis-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.analysis-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.select-comp-width {
  min-width: 200px !important;
}

.auto-complete-comp-width {
  min-width: 300px !important;
}

.select-comp-height {
  height: 50px !important;
  text-align: left !important;
}

.filters-grid {
  margin-top: 70px !important;
  padding: 0 25px 0 50px !important;
  margin-bottom: 14px !important;
}

.sales-summary-background {
  background-color: white !important;
  position: relative;
  width: fit-content !important;
  padding: 5px 15px 10px 20px !important;
  border-radius: 20px !important;
  margin-top: 12px !important;
}

.commoditywise-orders-style {
  margin-right: 24px !important;
  width: fit-content !important;
}

@media screen and (min-width: 1950px) {
  .sales-summary-background {
    width: 66.5% !important;
  }
}

@media screen and (min-width: 1950px) {
  .commoditywise-orders-style {
    width: 25% !important;
  }
}

.chart-header-text {
  color: var(--kpi-analysis-font-color) !important;
  font-weight: bold !important;
  text-align: left !important;
  padding: 5px !important;
}

.analysis-cards-div-style {
  border-radius: 15px !important;
  height: 140px !important;
  width: 145px !important;
  margin: 15px 50px 10px 10px !important;
}

.analysis-cards-bg1 {
  background-color: #f4e8ff;
}

.analysis-cards-bg2 {
  background-color: #dcfce7;
}

.analysis-cards-bg3 {
  background-color: #fff4de;
}

.analysis-cards-bg4 {
  background-color: #ffe2e6;
}

.analysis-overall-box {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  margin-left: 10px !important;
}

.analysis-card-box {
  border-radius: 80% !important;
  padding: 6px !important;
  margin-top: 10px !important;
  display: inline-block !important;
}

.analysis-card-box-bg1 {
  background-color: #d5abfe !important;
}

.analysis-card-box-bg2 {
  background-color: #7be791 !important;
}

.analysis-card-box-bg3 {
  background-color: #fdbfa7 !important;
}

.analysis-card-box-bg4 {
  background-color: #fd95ac !important;
}

.analysis-card-title {
  text-align: left !important;
  margin-top: 20px !important;
  font-size: var(--font-size) !important;
  color: #847a83 !important;
}

.analysis-card-value {
  text-align: left !important;
  margin-top: 14px !important;
  font-size: var(--font-size) !important;
  font-weight: bold !important;
}

.donut-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-summary-background {
  background-color: white !important;
  position: relative;
  width: 420px !important;
  height: fit-content !important;
  padding: 5px 15px 5px 20px !important;
  border-radius: 20px !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}

@media screen and (min-width: 1950px) {
  .chart-summary-background {
    width: 30% !important;
  }
}

.analysis-chart-stack {
  gap: 30px !important;
  margin-top: 15px !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.analysis-header-stack {
  gap: 50px !important;
}

.no-data-text {
  width: 100% !important;
  height: 365px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.brand-no-data-text {
  height: 165px !important;
}

.sales-summary-icon {
  color: white !important;
}

.sales-summary-cash-icon {
  width: 24px !important;
  height: 24px !important;
  filter: brightness(0) invert(1) !important;
}

.tooltip-table {
  width: 100%;
  border-collapse: collapse;
}

.tooltip-table th,
.tooltip-table td {
  text-align: left;
  padding: 4px;
}

.tooltip-table td {
  border-bottom: 1px solid #ddd;
}
